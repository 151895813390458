import React from 'react'
import ReactTooltip from 'react-tooltip'
import Icon from '../Icon'
import Button from '../Button'
import AndroidQrCode from './qrcode-get-wallet.svg'

import './GetWalletActions.scss'

function GetWallet() {
  return (
    <div className="Wallet-actions">
      <div className="Banner-actions">
        <ReactTooltip id="registerTip" place="top" effect="solid" type="light" className="QqCodeTooltip">
          <img src={AndroidQrCode} alt="" />
        </ReactTooltip>
        <Button
          className="Button-circle"
          text="App Store"
          icon="app-store"
          link="https://apps.apple.com/us/app/gym2gym/id1660419057" yellow newTab />
        <div className="sm-hidden" role="button" data-for="registerTip" data-tip>
          <Icon name="qr-code" />
        </div>
      </div>
    </div>
  )
}

export default GetWallet
