import React from 'react'

import Container from '../Container'
import ens from './ens.svg'
import chainlink from './chainlink.svg'
import ethereum from './ethereum.svg'

import './Featured.scss'

function FeaturedIn() {
  return (
    <Container>
      <div className="Featured">
        <div className="Featured-text Page-header-text text-gradient">Gym2Gym Is Growing</div>
        <div className="Featured-list">
          <a target="_blank" href="https://ethereum.org/en/wallets/find-wallet/" rel="noopener noreferrer">
            <img className="Featured-item" src={ethereum} alt="Ethereum" />
          </a>
          <a target="_blank" href="https://ens.domains/" rel="noopener noreferrer">
            <img className="Featured-item" src={ens} alt="ENS" />
          </a>
          <a target="_blank" href="https://chain.link/" rel="noopener noreferrer">
            <img className="Featured-item" src={chainlink} alt="ChainLink" />
          </a>
        </div>
      </div>
    </Container>
  )
}

export default FeaturedIn
