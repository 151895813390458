import React, { useState } from 'react'

import Home from '../Home'
import Page from '../Page'
import Feature from '../Feature'
import Footer from '../Footer/Footer'
import Join from '../Join/Join'
import GetWallet from '../GetWallet'
import Demo from '../Demo'

import './App.scss'

function App() {
  const [feature, setFeature] = useState({
    hand: '',
    face: '',
    independence: ''
  })

  const onMouseEnter = (key, value) => {
    if (feature[key] !== value) {
      setFeature({ [key]: value })
    }
  }

  const onMouseLeave = (key) =>
    setFeature({ [key]: null })

  return (
    <div className="App">
      <div id="page-1" className="Page-black">
        <Home />
      </div>

      <div id="page-3" className="Page-dark">
        <Page img="member"
              onMouseLeave={() => onMouseLeave('hand')}
              featureImg={feature.hand}
              text="For Members">

          <Feature
            icon="non-custodial"
            onMouseEnter={() => onMouseEnter('hand', 'non-cost')}
            isActive={feature.hand === 'non-cost'}
            title="Workout privately"
            info="Workout without the crowds or distractions, in your own space" />

          <Feature
            icon="wallet"
            onMouseEnter={() => onMouseEnter('hand', 'wallet')}
            isActive={feature.hand === 'wallet'}
            title="Flexbile choices"
            info="Choose from a wide range of curated private gyms, wellness spaces, and home gyms" />

          <Feature
            icon="blocks"
            onMouseEnter={() => onMouseEnter('hand', 'portfolio')}
            isActive={feature.hand === 'portfolio'}
            title="No strings attached"
            info="Pay only for what you use, with no membership fees or long-term commitments" />
        </Page>
      </div>
      <div id="page-4" className="Page-black">
        <Page img="owner"
              onMouseLeave={() => onMouseLeave('independence')}
              featureImg={feature.independence}
              text="For Owners">

          <Feature
            icon="swap"
            onMouseEnter={() => onMouseEnter('independence', 'swap')}
            isActive={feature.independence === 'swap'}
            title="Flexible booking"
            info="List your space for whenever you want. No commitment, only income." />

          <Feature
            icon="chart"
            onMouseEnter={() => onMouseEnter('independence', 'market')}
            isActive={feature.independence === 'market'}
            title="Minimal setup"
            info="No setup required. just list your space, and watch as the bookings come in" />

          <Feature
            icon="ring"
            onMouseEnter={() => onMouseEnter('independence', 'search')}
            isActive={feature.independence === 'search'}
            title="Special Deals"
            info="Offer custom deals and receive additional perks being an owner" />
        </Page>
      </div>
      <div id="page-5" className="Page-dark">
        <Page img="trainer"
              onMouseLeave={() => onMouseLeave('face')}
              featureImg={feature.face}
              text="For Trainers">

          <Feature
            icon="user"
            onMouseEnter={() => onMouseEnter('face', 'no-data')}
            isActive={feature.face === 'no-data'}
            title="No more searching"
            info="Once your client books you and their space, all you have to do is show up and train!" />
          <Feature
            icon="stop"
            onMouseEnter={() => onMouseEnter('face', 'no-kyc')}
            isActive={feature.face === 'no-kyc'}
            title="Grow your clients"
            info="Reach a wider audience looking for personal training services, without any extra marketing effort" />
          <Feature
            icon="incognito"
            onMouseEnter={() => onMouseEnter('face', 'privacy')}
            isActive={feature.face === 'privacy'}
            title="Flexible scheduling"
            info="Manage your schedule with ease, allowing clients to book sessions at times that work for both of you" />

        </Page>
      </div>

      <div className="Page-black">
        <Demo />
      </div>

      <div className="Page-black">
        <div className="Wallet-download">
          <GetWallet />
        </div>
      </div>

      <div id="page-8" className="Page-dark">
        <Join />
      </div>

      <div id="page-9" className="Page-dark">
        <Footer />
      </div>
    </div>
  )
}

export default App
