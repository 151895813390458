import React, { useState } from 'react'
import cn from 'classnames'
import Container from '../Container'
import YoutubeEmbed from '../Video/YoutubeEmbed'
import './Demo.scss'

import { ReactComponent as One } from './one.svg'
import { ReactComponent as Two } from './two.svg'
import { ReactComponent as Three } from './three.svg'
import { ReactComponent as Four } from './four.svg'

function Demo() {
  const [active, setActive] = useState('bitcoinWallet')
  const isActive = name => active === name ? 'active' : null
  const setState = val => () => setActive(val)

  const map = {
    bitcoinWallet: { videoId: '0QAhxM4GUgQ', disabled: false },
    multiWallet: { videoId: '', disabled: true },
    nftWallet: { videoId: '', disabled: true },
    watchAddress: { videoId: '', disabled: true },
  }

  return (
    <Container>
      <div className="Demo Section-space-top Section-space-bottom">
        <div className="Page-header-text text-center pb-5 text-gradient">
          Demo Videos
        </div>

        <div className="Demo-container mt-5 pt-5">
          <div className="Demo-content">
            <TabContent key={active} isActive={isActive}>
              <YoutubeEmbed embedId={map[active].videoId} />
            </TabContent>
          </div>
          <div className="Demo-tabs">
            <div className="tablink-header">Demo Playlist</div>
            <Tab item="bitcoinWallet" Icon={One} name="Members" isActive={isActive} setActive={setState} isDisabled={map.bitcoinWallet.disabled} />
            <Tab item="multiWallet" Icon={Two} name="Owners" isActive={isActive} setActive={setState} isDisabled={map.multiWallet.disabled} />
            <Tab item="nftWallet" Icon={Three} name="Trainers" isActive={isActive} setActive={setState} isDisabled={map.nftWallet.disabled} />
            <Tab item="watchAddress" Icon={Four} name="Long Demo" isActive={isActive} setActive={setState} isDisabled={map.watchAddress.disabled} />
          </div>
        </div>
      </div>
    </Container>
  )
}

const Tab = ({ name, item, Icon, isDisabled, isActive = () => false, setActive = () => false }) => (
  <div className={cn('tablink', isActive(item), { disabled: isDisabled })} onClick={isDisabled ? null : setActive(item)}>
    <Icon />
    <span className="ms-3">{isDisabled ? 'Feature Coming Soon' : name}</span>
  </div>
)

const TabContent = ({ children }) => (
  <div className="tabcontent">
    {children}
  </div>
)

export default Demo
