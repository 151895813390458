import React, { Component } from 'react';
import './RefLink.scss';
import QRCode from 'qrcode.react';

class RefLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        firstName: '', // Capture the user's first name
        g2gId: '', // Generate a unique g2gID
      },
      submitted: false,
      generatedLink: '',
      copied: false,
    };
  }

  generateUniqueID = () => {
    return `g2g-${Math.random().toString(36).substr(2, 9)}`;
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { firstName } = this.state.formData;
    if (firstName) { // Check if a first name is entered
      const g2gId = this.generateUniqueID();
      const generatedLink = `https://gym2gym.com?name=${firstName}-${g2gId}`;
      this.setState({ submitted: true, generatedLink });
      this.trackConversion(firstName, g2gId, generatedLink); // Track the affiliate link when generated
    }
  };

  trackConversion = async (firstName, g2gId, generatedLink) => {
    const dataToSend = {
      firstName, // User's first name
      g2gId, // Unique g2gID
      generatedLink, // Affiliated URL link
      conversion: 'signedUp', // Assuming a 'signup' conversion event
      timestamp: new Date().toLocaleString(),
    };

    try {
      // Send the data to Google Sheets using NoCode API
      const response = await fetch(
        'https://v1.nocodeapi.com/k3yton/google_sheets/pldqGrqltxPUVFJx/addRows?tabId=ConversionData', // Update with your endpoint
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([dataToSend]),
        }
      );

      if (response.status === 200) {
        console.log('Conversion recorded successfully');
      } else {
        console.error('Error recording conversion:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording conversion:', error);
    }

    // After tracking the conversion, track the usage
    this.trackUsage(generatedLink);
  };

  trackUsage = async (generatedLink) => {
    const g2gId = generatedLink.split('-')[2]; // Extract the g2gId from the generated link
  
    const dataToSend = {
      timestamp: new Date().toLocaleString(),
      referrer: g2gId, // Use the g2gId as the referrer
      affiliateLink: generatedLink, // Record the affiliate link.
    };
  
    try {
      const response = await fetch(
        'https://v1.nocodeapi.com/k3yton/google_sheets/pldqGrqltxPUVFJx/addRows?tabId=TrackUsage', // Update with your endpoint
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify([dataToSend]),
        }
      );
  
      if (response.status === 200) {
        console.log('Usage recorded successfully');
      } else {
        console.error('Error recording usage:', response.statusText);
      }
    } catch (error) {
      console.error('Error recording usage:', error);
    }
  };
  
  handleCopyClick = () => {
    const { generatedLink } = this.state;
    const el = document.createElement('textarea');
    el.value = generatedLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copied: true });
  };

  render() {
    const { submitted, formData, generatedLink, copied } = this.state;

    return (
      <div className="ref-link-container">
        <h2>Create Affiliate Link</h2>
        {submitted ? (
          <div className="RefLink-success">
            <h3>Created Successfully</h3>
            {generatedLink && (
              <div>
                <a href={generatedLink} target="_blank" rel="noopener noreferrer">
                  {generatedLink}
                </a>
                <br></br>
                <button onClick={this.handleCopyClick}>
                  {copied ? 'Copied!' : 'Copy URL'}
                </button>
                <div>
                  <p>QR Code:</p>
                  <QRCode value={generatedLink} />
                </div>
              </div>
            )}
          </div>
        ) : (
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">Your First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={this.handleInputChange}
                required // Make the first name field required
              />
            </div>
            <button type="submit">Generate Link</button>
          </form>
        )}
      </div>
    );
  }
}

export default RefLink;
