import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import App from './components/App/App';
import Faq from './components/Faq/Faq';
import Privacy from './components/Privacy/Privacy';
import ContactForm from './components/Contact/ContactForm';
import NotFound from './components/NotFound/404';

function Routes() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/404" component={NotFound} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/faq" component={Faq} />
        <Route path="/form" component={ContactForm} />
        <Route path="/" component={App} />
      </Switch>
    </HashRouter>
  );
}

export default Routes;
