import React, { Component } from 'react';
import './ContactForm.scss';
import Header from '../Header';
import Footer from '../Footer/Footer';
import Container from '../Container';
import RefLink from '../RefLink/RefLink';

class ContactForm extends Component {
  render() {
    return (
      <div>
        <Header />
        <Container>
          <div className="Contact-form">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfPELs7Vs6Ztj66gHFlIhx5NkI2IZBt4MVnUUIAhgGID46dDA/viewform?embedded=true"
              width="640"
              height="4749"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Contact Form"
            >
              Loading…
            </iframe>
          </div>
        </Container>
        <RefLink />
        <Footer />
      </div>
    );
  }
}

export default ContactForm;
