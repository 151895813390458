import React from 'react'
import Container from '../Container'

import twitter from './twit.png'
import insta from './insta.png'
import discord from './discord.png'
import fb from './fb.png'
import tiktok from './tiktok.png'
import linkedin from './linkedin.png'

import './Join.scss'

function Join() {
  return (
    <Container clipped={false}>
      <div className="Section-head Section-join Page-rtl">
        <div className="Page-content">
          <div className="Page-end">
            <div className="Section-text Join-section-text text-gradient">
              Join our community!
            </div>
            <div className="Section-info Join-section-info">
              Follow and stay up to date on the latest Gym2Gym trends.
           </div>
          </div>
          <div className="Page-start">
            <div className="Join-icons">
              <div className="Join-icon">
                <a href="https://twitter.com/Gym2GymInc" target="_blank" rel="noopener noreferrer">
                  <img className="Join-image" src={twitter} alt="Twitter" />
                </a>
              </div>
              <div className="Join-icon">
                <a href="https://www.instagram.com/gym2gym_inc/" target="_blank" rel="noopener noreferrer">
                  <img className="Join-image" src={insta} alt="github" />
                </a>
              </div>
              <div className="Join-icon">
                <a href="https://www.facebook.com/profile.php?id=100090328166226" target="_blank" rel="noopener noreferrer">
                  <img className="Join-image" src={fb} alt="telegram" />
                </a>
              </div>
            </div>
            <div className="Join-icons">
              <div className="Join-icon">
                <a href="https://www.linkedin.com/company/gym2gym-inc/" target="_blank" rel="noopener noreferrer">
                  <img className="Join-image" src={linkedin} alt="reddit" />
                </a>
              </div>
              <div className="Join-icon">
                <a href="https://discord.gg/6raQazhepk" target="_blank" rel="noopener noreferrer">
                  <img className="Join-image" src={discord} alt="discord" />
                </a>
              </div>
              <div className="Join-icon">
                <a href="https://www.tiktok.com/@gym2gyminc" target="_blank" rel="noopener noreferrer">
                  <img className="Join-image" src={tiktok} alt="medium" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Join

